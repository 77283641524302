<template>
	<div class="footer-links">
		<transition name="fade" mode="out-in">
			<div class="footer-links__content">
				<template v-if="isLoad">
					<div
						class="footer-links__container"
						v-for="(item, index) in content"
						:key="index"
					>
						<h3>{{ item.title }}</h3>
						<ul class="footer-links__menu">
							<template v-for="(el, index) in item.list">
								<li v-if="el.title" :key="index">
									<router-link
										v-if="!el.slug"
										:to="{
											name: 'MainRoute',
											params: { lang: $store.state.uiLanguage }
										}"
									>
										{{ el.title }}
									</router-link>
									<router-link v-else :to="correctLinkName(item.type, el)">
										{{ el.title }}
									</router-link>
								</li>
							</template>
						</ul>
					</div>
				</template>
				<template v-else>
					<div class="footer-links__container" v-for="n in 4" :key="n">
						<or-skeleton
							style="height: 20px; width: 165px; margin-bottom: 10px"
						/>
						<ul class="footer-links__menu">
							<li v-for="n in 5" :key="n">
								<or-skeleton style="height: 20px; width: 165px" />
							</li>
						</ul>
					</div>
				</template>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		content: {
			type: Array,
			default: () => []
		},
		isLoad: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		correctLinkName(type, element) {
			return {
				custom: {
					name: element?.slug,
					params: { lang: this.$store.state.uiLanguage }
				},
				developer: {
					name: 'SoloDeveloper',
					params: {
						slug: element?.slug,
						lang: this.$store.state.uiLanguage
					}
				},
				areas: {
					name: 'SoloArea',
					params: {
						slug: element?.slug,
						lang: this.$store.state.uiLanguage
					}
				},
				search: {
					name: 'Search',
					params: {
						lang: this.$store.state.uiLanguage
					},
					query: {
						type_id: this.$store.state.adsType.find(
							(itm) => itm.title === element?.title
						)?.id
					}
				}
			}[type]
		}
	}
}
</script>

<style lang="scss">
.footer-links {
	&__content {
		display: flex;
		gap: 20px;
		flex-wrap: wrap;
		margin-bottom: 30px;
		padding-bottom: 55px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	&__container {
		flex: 1 1 auto;

		h3 {
			margin: 0 0 20px 0;
			font-weight: 700;
			font-size: 18px;
			line-height: 25px;
		}
	}

	&__menu {
		display: flex;
		flex-direction: column;
		gap: 10px;

		a {
			color: #808080;
			font-size: 14px;
			line-height: 18px;

			&:hover {
				color: #c99d56;
			}
		}
	}
}
</style>
